import React, { useState } from "react"

const Form = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    text: "",
  });

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {   
    e.preventDefault()
    if (formState.name === '' && formState.email === '' && formState.text === '') {
      return
    } else {
       fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() =>
        alert(
          "Спасибо за обращение! Мы обязательно свяжемся с вами в самое ближайшее время!"
        )
      )
      .catch(error => alert(error))
    }
  }
  return (
    <div className="pb-8 pt-24">
      <div className="m-0 mb-4">
        <h2
          className="text-white uppercase"
          style={{ fontFamily: `Montserrat` }}
        >
          Напишите нам
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="flex flex-col md:px-12"
      >
        <p className="mx-8" style={{ fontFamily: `Montserrat` }}>
          <label htmlFor="name" className=" flex flex-col text-white">
            Ваше имя
            <input
              className="h-10 rounded text-white"
              id="name"
              type="text"
              name="name"
              onChange={handleChange}
              value={formState.name}
              placeholder=" Имя"
              style={{
                border: `1px solid #3d4b5a`,
                backgroundColor: `#1b2730`,
              }}
            />
          </label>
        </p>
        <p className=" mx-8 mt-4" style={{ fontFamily: `Montserrat` }}>
          <label htmlFor="email" className="flex flex-col text-white">
            Email
            <input
              className="h-10 rounded text-white"
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              value={formState.email}
              placeholder=" Email"
              style={{
                border: `1px solid #3d4b5a`,
                backgroundColor: `#1b2730`,
                fontFamily: `Montserrat`,
              }}
            />
          </label>
        </p>
        <p className="mx-8 mt-4" style={{ fontFamily: `Montserrat` }}>
          <label htmlFor="text" className="flex flex-col text-white">
            Ваш вопрос
            <textarea
              className="h-24 rounded text-white"
              name="text"
              id="text"
              type="text"
              onChange={handleChange}
              value={formState.text}
              placeholder=" Ваше сообщение"
              style={{
                border: `1px solid #3d4b5a`,
                backgroundColor: `#1b2730`,
              }}
            ></textarea>
          </label>
        </p>

        <p className="mx-8 mt-16" style={{ fontFamily: `Montserrat` }}>
          <button
            className="bg-transparent text-white uppercase font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            type="submit"
          >
            отправить
          </button>
        </p>
      </form>
    </div>
  )
}

export default Form
