import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout"
import Form from "../components/form"


const Proekt = ({ data }) => {
  return (
    <Layout>
      <div className="mt-8">
        <div className="bg-transparent">
          <h2
            className="font-bold text-2xl md:text-3xl lg:text-4xl xl:text-4xl"
            style={{
              fontFamily: `Montserrat`,
              color: `white`,
              padding: `1rem`,
            }}
          >
            ПРОЕКТИРОВАНИЕ
          </h2>
        </div>
      </div>
      <div className="mt-20">
        <div className="px-2 py-2 ">
          <Img
            fluid={data.contentfulProject.image.fluid}
            alt={data.contentfulProject.title}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
        <div className="p-2 mt-4" style={{ fontFamily: `Montserrat` }}>
          <h3
            className="text-white mb-8 text-1xl md:text-2xl lg:text-2xl xl:text-2xl"
            style={{ fontFamily: `Montserrat` }}
          >
            {data.contentfulProject.title}
          </h3>
          {/* <p className="text-white">{data.strapiProject.description}</p> */}
          <p className="text-white text-sm md:text-sm lg:text-sm xl:text-sm">
            {documentToReactComponents(data.contentfulProject.description.json)}
          </p>
        </div>
      </div>
      <div>
        <Form />
      </div>
    </Layout>
  )
}

export default Proekt

export const PageQuery = graphql`
  query Proekt {
    contentfulProject {
      image {
        fluid(maxWidth: 600, quality: 35) {
          ...GatsbyContentfulFluid
        }
      }
      title
      description {
        json
      }
    }
  }
`
